.right-column {
    padding-left: 2em;
    padding-right: 2em;
    border-left: 3px solid #f8f9fa;
}

.scroll-questions-content {
    max-height: 500px;
    overflow: scroll;
    margin-bottom: 20px;
}
