.header {
    background-color: #f8f9fa;
    text-align: center;
    font-weight: bold;
    padding: .5em;
}

.body {
    background-color: #f8f9fa;
    text-align: center;
    padding: .5em;
}